<template>
  <v-container v-if="!loading" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-office-building"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("thesis", 2) }}</b>
        </h1>
      </template>
      <v-btn
        data-test="Thesis:ThesesTable:BtnCreateNewThesis"
        color="primary"
        rounded
        @click="add_thesis()"
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th class="text-center">
              {{ $t("details") }}
            </th>
            <th class="text-center">
              {{ $t("edit") }}
            </th>
            <th class="text-center">
              {{ $t("remove") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(thesis, index) in theses"
            :key="index"
            data-test="Thesis:ThesesTable:TableItem"
          >
            <td :data-test="`Theses:ThesesTable:TdName:${thesis.name}`">
              {{ thesis.name }}
            </td>
            <td class="text-center">
              <v-btn
                :data-test="`Thesis:ThesesTable:BtnSeeThesis${index}`"
                class="px-2 ml-1 primary"
                @click="redirect_details(thesis.id)"
                min-width="0"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                :data-test="`Thesis:ThesesTable:BtnEditThesis${index}`"
                class="px-2 ml-1 primary"
                @click="edit_thesis(thesis.id)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                :data-test="`Thesis:ThesesTable:BtnDeleteThesis${index}`"
                class="px-2 ml-1"
                @click="open_delete_dialog(thesis)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="delete_thesis"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-row v-else justify="center" align="center" class="pa-4">
    <v-progress-circular
      indeterminate
      size="40"
      color="primary"
    ></v-progress-circular>
  </v-row>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";

export default {
  name: "ThesesTable",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    delete_dialog: false,
    delete_obj: null,
    theses: [],
  }),
  async created() {
    await this.get_theses_list();
  },
  methods: {
    redirect_details: function (thesis_id) {
      this.$router.push({
        path: `/theses/details/${thesis_id}`,
      });
    },
    async get_theses_list() {
      this.loading = true;
      await this.apiService
        .getRequest("thesis/list-all")
        .then((resp) => {
          this.theses = resp.content;
        })
        .catch((error) => {});
      this.loading = false;
    },
    thesis_details: function (thesis_id) {
      this.$router.push({
        path: `theses`,
        params: { thesis_id: thesis_id },
      });
    },
    add_thesis: function () {
      this.$router.push({
        path: `/theses/add`,
      });
    },
    edit_thesis: function (thesis_id) {
      this.$router.push(`/theses/edit/${thesis_id}`);
    },
    open_delete_dialog: function (thesis) {
      this.delete_obj = thesis;
      this.delete_dialog = true;
    },
    delete_thesis: async function () {
      var index = this.theses.indexOf(this.delete_obj);
      this.theses.splice(index, 1);
      this.delete_dialog = false;
      await this.apiService
        .deleteRequest(`thesis/${this.delete_obj.id}`)
        .then((resp) => {
          this.$toast.success(this.$t("thesis_delete_successsful"));
        })
        .catch((error) => {});
    },
  },
};
</script>
